import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="route-map-reports--map"
export default class extends Controller {
  static values = {
    data: Object,
    farmLat: Number,
    farmLon: Number,
    lots: Array,
  };

  connect() {
    this.initializeMap();
    this.renderLots();
    this.renderMarkers();
    this.renderHeatmap();
    this.addControls();

    console.log(this.lotsValue);
  }

  initializeMap() {
    const maxZoomLevel = 22;
    this.map = L.map(this.element).setView(
      [this.farmLatValue, this.farmLonValue],
      13
    );

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);
  }

  renderLots() {
    const lotLayers = this.lotsValue.map((lot) => {
      const layer = L.geoJSON(JSON.parse(lot.polygon_geojson)).addTo(this.map);
      layer.bindTooltip(lot.name);
      return layer;
    });

    const bounds = L.featureGroup(lotLayers).getBounds();
    this.map.fitBounds(bounds);

    this.lotLayerGroup = L.layerGroup(lotLayers);
    this.lotLayerGroup.addTo(this.map);
  }

  renderHeatmap() {
    this.heatmapLayers = {};
    for (const [customFieldName, values] of Object.entries(
      this.dataValue.heatmap_data
    )) {
      if (!Array.isArray(values) || values.length === 0) {
        console.warn(`No valid data for ${customFieldName}`);
        continue;
      }

      const heatmapPoints = values
        .filter(
          (point) =>
            Array.isArray(point) &&
            point.length === 3 &&
            typeof point[0] === "number" &&
            typeof point[1] === "number" &&
            typeof point[2] === "number"
        )
        .map(([lat, lon, value]) => [lat, lon, value]);

      if (heatmapPoints.length === 0) {
        console.warn(`No valid points for ${customFieldName}`);
        continue;
      }

      const maxValue = Math.max(...heatmapPoints.map(([, , value]) => value));

      if (maxValue === 0) {
        console.warn(`All values are zero for ${customFieldName}`);
        continue;
      }

      try {
        this.heatmapLayers[`Mapa de calor: ${customFieldName}`] = L.heatLayer(
          heatmapPoints,
          {
            radius: 15,
            blur: 20,
            minOpacity: 0.4,
            gradient: {
              0.2: "blue",
              0.4: "cyan",
              0.6: "lime",
              0.8: "yellow",
              1.0: "red",
            },
          }
        ).addTo(this.map);
      } catch (error) {
        console.error(`Error creating heatmap for ${customFieldName}:`, error);
      }
    }
  }

  renderMarkers() {
    this.markerLayers = {};
    for (const [customFieldName, values] of Object.entries(
      this.dataValue.markers_data
    )) {
      const markerClusterGroup = L.markerClusterGroup({
        chunkedLoading: true,
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 19,
      });

      values.forEach(({ coordinates, color }) => {
        const marker = L.circleMarker(coordinates, {
          color: color,
          fillOpacity: 1,
        }).bindPopup(customFieldName);
        markerClusterGroup.addLayer(marker);
      });

      this.markerLayers[`Presencia: ${customFieldName}`] =
        markerClusterGroup.addTo(this.map);
    }
  }

  addControls() {
    L.control
      .layers(null, {
        ...this.heatmapLayers,
        ...this.markerLayers,
        lots: this.lotLayerGroup,
      })
      .addTo(this.map);
  }
}
